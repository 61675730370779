import { FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import React from 'react';

type FilterRepositoriesProps = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  activeOnly: boolean;
  toggleActiveOnly: () => void;
};

export default function FilterRepositories({
  search,
  setSearch,
  activeOnly,
  toggleActiveOnly,
}: FilterRepositoriesProps) {
  return (
    <>
      <TextField value={search} onChange={(e) => setSearch(e.target.value)} label="Search" size="small" autoFocus />
      <FormGroup>
        <FormControlLabel control={<Switch checked={activeOnly} onChange={toggleActiveOnly} />} label="Active only" />
      </FormGroup>
    </>
  );
}
