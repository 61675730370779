import PageHeading from '@boilerplate/components/PageHeading';
import { LockOutlined as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';
import { Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import config from '@/config';
import BranchModel from '@/entities/branch/BranchModel';
import humanSize from '@/lib/humanSize';
import Notistack from '@/lib/notistack';

import DownloadDatabaseBackup from '../DatabaseBackup/DownloadDatabaseBackup';
import PrettyDate from '../PrettyDate';
import StatusBall from '../StatusBall';

const useStyles = makeStyles()(({ palette }) => ({
  encrypted: {
    color: palette.success.main,
  },
  notEncrypted: {
    color: palette.warning.main,
  },
}));

function BranchPage() {
  const { branchId: branchParam } = useParams<{ branchId?: string }>();
  const { classes } = useStyles();

  const { branch, loading } = BranchModel.useGetData(branchParam || '');

  const handleBackup = useCallback(() => {
    Notistack.toast('Backup requested. Please wait a bit', { variant: 'success' });

    axios.post(`${config.app.backendUrl}/database-backups/backup/${branchParam}`).catch(() => {
      Notistack.toast('Something went wrong while requesting the backup', { variant: 'error' });
    });
  }, [branchParam]);

  const debouncedHandleBackup = useMemo(() => debounce(handleBackup, 5000, { leading: true, trailing: false }), [handleBackup]);

  if (!branch || loading) {
    return null;
  }

  return (
    <>
      <PageHeading title={`${branch.gitRepository?.name}/${branch.name}`} arrowBack />

      <Grid container gap={2} justifyContent="space-between" alignItems="center" mb={2}>
        <div>
          <Typography variant="body1" color="text.primary">
            Backups active
          </Typography>

          <StatusBall active={branch.backupDatabase} />
          {` `}
          <Typography variant="body2" color="text.secondary" component="span">
            {branch.backupDatabase ? 'active' : 'inactive'}
          </Typography>
        </div>

        <div>
          <Typography variant="body1" color="text.primary">
            Database server
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {branch?.databaseServer?.name}
          </Typography>
        </div>

        <div>
          <Typography variant="body1" color="text.primary">
            Database name
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {branch.databaseName}
          </Typography>
        </div>

        <Button variant="outlined" onClick={debouncedHandleBackup}>
          Backup
        </Button>
      </Grid>

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Date</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Encrypted</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {branch.databaseBackups.map((databaseBackup) => (
                <TableRow key={databaseBackup.id} hover>
                  <TableCell>
                    <PrettyDate date={databaseBackup.createdAt} variant="formatted" />
                  </TableCell>
                  <TableCell>
                    <PrettyDate date={databaseBackup.createdAt} />
                  </TableCell>
                  <TableCell>{humanSize(databaseBackup.size)}</TableCell>
                  <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <div className={databaseBackup.isEncrypted ? classes.encrypted : classes.notEncrypted}>
                      {databaseBackup.isEncrypted ? <LockIcon color="inherit" /> : <LockOpenIcon color="inherit" />}
                    </div>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <DownloadDatabaseBackup databaseBackupId={databaseBackup.id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default BranchPage;
