import React, { useMemo } from 'react';
import { formatDistanceToNow, format } from 'date-fns';

type PrettyDateProps = {
  date: string | Date;
  variant?: 'formatted' | 'relative';
};

export default function PrettyDate({ date, variant = 'relative' }: PrettyDateProps) {
  const dateObject = useMemo(() => (typeof date === 'string' ? new Date(date) : date), [date]);
  const distanceToNow = useMemo(() => formatDistanceToNow(dateObject, { addSuffix: true }), [dateObject]);
  const formattedDate = useMemo(() => format(dateObject, 'yyyy-MM-dd HH:mm'), [dateObject]);

  return (
    <span title={dateObject.toISOString()}>
      {variant === 'formatted' && <span style={{ fontFamily: 'monospace' }}>{formattedDate}</span>}

      {variant === 'relative' && distanceToNow}
    </span>
  );
}
