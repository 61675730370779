import { QueryHookOptions } from '@apollo/client';
import { compareDesc } from 'date-fns';
import { useMemo } from 'react';

import GitRepositoryBaseModel from '@/_base/gitRepository/GitRepositoryBaseModel';
import {
  GetRepositoriesOverviewQuery,
  GetRepositoriesOverviewQueryVariables,
  useGetRepositoriesOverviewQuery,
} from '@/graphql';

export type RepositoryRow = {
  gitRepositoryId: string;
  repositoryName: string;
  branchId: string;
  branchName: string;
  databaseServerName: string;
  backupDatabase: boolean;
  lastBackup?: {
    id: string;
    createdAt: Date;
    size: number;
  };
};

const GitRepositoryModel = {
  ...GitRepositoryBaseModel,

  useGetOverview: (
    baseOptions?: QueryHookOptions<GetRepositoriesOverviewQuery, GetRepositoriesOverviewQueryVariables>
  ) => {
    const hookResult = useGetRepositoriesOverviewQuery({
      fetchPolicy: 'cache-and-network',
      ...baseOptions,
    });

    const repositories = useMemo(() => {
      const newRows: RepositoryRow[] = [];

      if (!hookResult.data?.gitRepositories?.items) {
        return newRows;
      }

      hookResult.data.gitRepositories.items.forEach((repositoryItem) => {
        repositoryItem?.branches?.items?.forEach((branchItem) => {
          const newRow: RepositoryRow = {
            gitRepositoryId: repositoryItem.id,
            repositoryName: repositoryItem.name,
            branchId: branchItem.id,
            branchName: branchItem.name,
            databaseServerName: branchItem.databaseServer.name,
            backupDatabase: branchItem.backupDatabase,
          };

          let lastBackup;

          if (branchItem.databaseBackups.items) {
            let backups = branchItem.databaseBackups.items.map((databaseBackup) => ({
              ...databaseBackup,
              createdAt: new Date(databaseBackup.createdAt),
            }));

            backups = backups.sort((backupItemA, backupItemB) => {
              return compareDesc(backupItemA.createdAt, backupItemB.createdAt);
            });

            lastBackup = backups[0];
          }

          if (lastBackup) {
            newRow.lastBackup = {
              id: lastBackup.id,
              createdAt: lastBackup.createdAt,
              size: lastBackup.size,
            };
          }

          newRows.push(newRow);
        });
      });

      return newRows;
    }, [hookResult]);

    return {
      loading: hookResult.loading,
      repositories,
    };
  },
};

export default GitRepositoryModel;
