import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetBranchDocument,
  GetBranchQuery,
  GetBranchQueryVariables,
  GetBranchesDocument,
  GetBranchesQuery,
  GetBranchesQueryVariables,
  useGetBranchQuery,
  useGetBranchLazyQuery,
  useGetBranchesQuery,
  useGetBranchesLazyQuery,

    useGetAllRelatedDataForBranchQuery,
    useGetAllRelatedDataForBranchLazyQuery,
    GetAllRelatedDataForBranchQuery,
    GetAllRelatedDataForBranchQueryVariables,

  CreateBranchDocument,
  CreateBranchMutation,
  CreateBranchMutationVariables,
  useCreateBranchMutation,

  DeleteBranchDocument,
  DeleteBranchMutation,
  DeleteBranchMutationVariables,
  useDeleteBranchMutation,

  UpdateBranchDocument,
  UpdateBranchMutation,
  UpdateBranchMutationVariables,
  useUpdateBranchMutation,

    useCreatedBranchSubscription,
    useUpdatedBranchSubscription,
    useDeletedBranchSubscription,
    useRestoredBranchSubscription,
} from '@/graphql';

  type BranchCollection = Dictionary<NonNullable<GetBranchesQuery['branches']['items']>[number]>;

const BranchBaseModel = {
  get: (options: Omit<QueryOptions<GetBranchQueryVariables, GetBranchQuery>, 'query'>) => {
    return apolloClient.query<GetBranchQuery, GetBranchQueryVariables>({
      ...options,
      query: GetBranchDocument,
    })
    .then(({ data }) => data.branch);
  },

  useGet: useGetBranchQuery,

  getAll: (options?: Omit<QueryOptions<GetBranchesQueryVariables, GetBranchesQuery>, 'query'>) => {
    return apolloClient
      .query<GetBranchesQuery, GetBranchesQueryVariables>({
        ...options,
        query: GetBranchesDocument
      })
      .then(({ data }) => data.branches.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) => {
    const hookResult = useGetBranchesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.branches?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForBranchQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForBranchQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetBranchLazyQuery,

  useGetAllLazy: useGetBranchesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForBranchLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateBranchMutation, CreateBranchMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateBranchMutation, CreateBranchMutationVariables>({
      ...options,
      mutation: CreateBranchDocument,
    });
  },

  useCreate: useCreateBranchMutation,

  update: (options: Omit<MutationOptions<UpdateBranchMutation, UpdateBranchMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateBranchMutation, UpdateBranchMutationVariables>({
      ...options,
      mutation: UpdateBranchDocument,
    });
  },

  useUpdate: useUpdateBranchMutation,

  delete: (options: Omit<MutationOptions<DeleteBranchMutation, DeleteBranchMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteBranchMutation, DeleteBranchMutationVariables>({
      ...options,
      mutation: DeleteBranchDocument,
    });
  },

  useDelete: useDeleteBranchMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) => {
      const [collection, setCollection] = useState<BranchCollection>({});

      const { items, loading, error, refetch } = BranchBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedBranchSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdBranch?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdBranch.id]: data.createdBranch,
            }));
          }
        },
      });

      useUpdatedBranchSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedBranch?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedBranch.id]: data.updatedBranch,
            }));
          }
        },
      });

      useDeletedBranchSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedBranch?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedBranch.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredBranchSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredBranch?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredBranch.id]: data.restoredBranch,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default BranchBaseModel;
