export default function humanSize(size: number) {
  const humanSizes = ['B', 'kB', 'MB', 'GB', 'TB'];
  const factor = 1024;

  let newSize = factor;
  let power = 0;

  while (newSize >= factor && power < humanSizes.length) {
    newSize = size / Math.pow(factor, power);
    power++;
  }

  return `${Math.round(newSize * 100) / 100} ${humanSizes[power - 1]}`;
}
