import PageCard from '@boilerplate/components/PageCard';
import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import GitRepositoryModel from '@/entities/gitRepository/GitRepositoryModel';

import FilterRepositories from './FilterRepositories';
import RepositoryRow from './RepositoryRow';

export default function RepositoriesPage() {
  const [activeOnly, setActiveOnly] = useState(true);
  const [search, setSearch] = useState('');
  const { loading, repositories: allRepositories } = GitRepositoryModel.useGetOverview();

  const activeRepositories = useMemo(() => allRepositories.filter((repository) => !!repository.backupDatabase), [allRepositories]);

  const repositories = useMemo(() => {
    const selectedRepositories = activeOnly ? activeRepositories : allRepositories;

    if (!search) {
      return selectedRepositories;
    }

    const preparedSearch = new RegExp(search.toLowerCase());

    return selectedRepositories.filter((repository) => {
      return repository.repositoryName.toLowerCase().search(preparedSearch) !== -1;
    });
  }, [search, activeOnly, activeRepositories, allRepositories]);

  const toggleActiveOnly = useCallback(() => {
    setActiveOnly((prev) => !prev);
  }, [setActiveOnly]);

  return (
    <PageCard
      heading="Database backups"
      headingRight={<FilterRepositories search={search} setSearch={setSearch} activeOnly={activeOnly} toggleActiveOnly={toggleActiveOnly} />}
      noBody
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 45 }} />
              <TableCell style={{ width: '1%' }}>Repository</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Server</TableCell>
              <TableCell>Last backup</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {repositories.map((row, index) => (
              <RepositoryRow
                key={row.branchId}
                repository={row}
                showRepositoryName={row.gitRepositoryId !== repositories[index - 1]?.gitRepositoryId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!repositories.length && loading && (
        <CardContent>
          <Typography variant="body1">Loading...</Typography>
        </CardContent>
      )}
    </PageCard>
  );
}
