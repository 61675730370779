import React from 'react';

type StatusBallProps = {
  active: boolean;
  size?: number;
};

export default function StatusBall({ active, size = 8 }: StatusBallProps) {
  const backgroundColor = active ? 'green' : 'red';

  return <span style={{ display: 'inline-block', height: size, width: size, borderRadius: '50%', backgroundColor }} />;
}
