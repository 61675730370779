import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { RepositoryRow as RepositoryRowType } from '@/entities/gitRepository/GitRepositoryModel';
import humanSize from '@/lib/humanSize';

import PrettyDate from '../PrettyDate';
import StatusBall from '../StatusBall';

type RepositoryRowProps = {
  repository: RepositoryRowType;
  showRepositoryName: boolean;
};

function RepositoryRow({ repository, showRepositoryName }: RepositoryRowProps) {
  return (
    <TableRow hover component={Link} to={`/branches/${repository.branchId}`}>
      <TableCell>
        <StatusBall active={repository.backupDatabase} />
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{showRepositoryName && repository.repositoryName}</TableCell>
      <TableCell>{repository.branchName}</TableCell>
      <TableCell>{repository.databaseServerName}</TableCell>
      <TableCell>{!!repository.lastBackup && <PrettyDate date={repository.lastBackup.createdAt} />}</TableCell>
      <TableCell>{!!repository.lastBackup && humanSize(repository.lastBackup.size)}</TableCell>
    </TableRow>
  );
}

export default React.memo(RepositoryRow);
