import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton } from '@mui/material';
import React from 'react';

import config from '@/config';

type DownloadDatabaseBackupProps = {
  databaseBackupId: string;
};

export default function DownloadDatabaseBackup({ databaseBackupId }: DownloadDatabaseBackupProps) {
  return (
    <IconButton
      title="download"
      aria-label="download"
      target="_blank"
      href={`${config.app.backendUrl}/database-backups/${databaseBackupId}/download`}
    >
      <FileDownloadOutlinedIcon />
    </IconButton>
  );
}
