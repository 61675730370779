import { compareDesc } from 'date-fns';
import { useMemo } from 'react';

import BranchBaseModel from '@/_base/branch/BranchBaseModel';
import { useGetBranchDataQuery } from '@/graphql';

const BranchModel = {
  ...BranchBaseModel,
  useGetData: (branchId: string) => {
    const hookResult = useGetBranchDataQuery({
      skip: !branchId,
      variables: {
        branchId,
      },
    });

    const branch = useMemo(() => {
      if (!hookResult.data?.branch) {
        return undefined;
      }

      let databaseBackups = (hookResult.data.branch.databaseBackups?.items || []).map((databaseBackup) => ({
        ...databaseBackup,
        createdAt: new Date(databaseBackup.createdAt),
      }));

      databaseBackups = databaseBackups.sort((databaseBackupA, databaseBackupB) =>
        compareDesc(databaseBackupA.createdAt, databaseBackupB.createdAt)
      );

      return {
        ...hookResult.data.branch,
        databaseBackups,
      };
    }, [hookResult]);

    return {
      ...hookResult,
      branch,
    };
  },
};

export default BranchModel;
